<template>
    <div class="card box widget-social pt-2 pb-2" :class="[alarmStatus,componentStyle]">
        <div v-if="isLoading">
            <loader-skeleton></loader-skeleton>
        </div>
        <OverlayPanel class="param-overlay" ref="periods" appendTo="body" :autoZIndex="true" @show="focusElement" 
            :dismissable="false" :showCloseIcon="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: '300px' }">
        </OverlayPanel>
        <Dialog 
            v-model:visible="showDiagPeriods"
            @show="focusDiag"
            @hide="hideOP"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '40vw' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ Belichtungs-Perioden Einstellungen
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('periods')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true" :activeIndex="diagPeriod">
                    <TabPanel v-for="(element, index) in this.nodeDataPeriods" :key="index">
                        <!-- <Panel :header="returnParameter(element, 'parameter', 'PeriodName').value + ' - Sollwert'"> -->
                        <template #header>
                            <div class="flex align-items-center gap-2">
                                <span class="white-space-nowrap" :class="this.getDiagDataPeriods(index, 'periodEnable').value ? '' : 'line-through'">{{ index+1 }}: {{ this.getDiagDataPeriods(index, 'periodName').value }}</span>
                            </div>
                        </template>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Perioden-Hauptschalter`">
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataPeriods(index, 'periodEnable').value" /><span class="ml-2">Periode aktivieren</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>    
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Zeiteinstellungen`" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-time-fast bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataPeriods(index, 'TimeModeStart').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataPeriods(index, 'TimeModeStart').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataPeriods(index, 'TimeSetStart').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus" />
                                            </span>
                                            <span class="p-float-label mr-2">
                                                <InputMask id="minTimeStart" ref="minValueTimeStart" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataPeriods(index, 'TimeSetStart').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="minValueTimeStart" class="mr-2">Periode Beginn</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataPeriods(index, 'TimeModeEnd').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataPeriods(index, 'TimeModeEnd').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataPeriods(index, 'TimeSetEnd').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus"/>
                                            </span>
                                            <span class="p-float-label">
                                                <InputMask id="minTimeEnd" ref="minValueTimeEnd" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataPeriods(index, 'TimeSetEnd').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="minValueTimeEnd">Periode Ende</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Lichtgruppen`" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Die Lichtgruppen sind fest definiert durch die Stromkreise in der Anlage (Plan beachten). Hier wird ausgewählt, welche Lichtgruppe in welcher Periode aktiv sein soll.`"></i>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            
                            <div class="formgrid grid">
                                <div class="field col mr-2">
                                    <div class="card flex flex-wrap justify-content-center gap-3">
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="this.getDiagDataPeriods(index, 'illuminationGroup1').value" inputId="illuminationGroup1" :binary="true" />
                                            <label for="illuminationGroup1" class="ml-2"> Lichtkreis 1 </label>
                                        </div>
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="this.getDiagDataPeriods(index, 'illuminationGroup2').value" inputId="illuminationGroup2" :binary="true" class="ml-3"/>
                                            <label for="illuminationGroup2" class="ml-2"> Lichtkreis 2 </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPeriods(index, 'periodName').value} - Strahlungsprogramm`" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Das Strahlungsprogramm schaltet die Belichtung in der freigegebenen Periode unterhalb des <b>Strahlung Sollwert</b> (Globalstrahlung Außen) ein. Die Schirmdämpfung (Allgemein) gibt die Lichtdämpfung in % durch den Schattierschirm an.`"></i>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            
                            <div class="formgrid grid">
                                <div class="field col mr-2">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="radiationSetpoint" ref="radiationSetpoint" :min="0" :max="2000"
                                                v-model="this.getDiagDataPeriods(index, 'radiationSetpoint').value" autofocus @focus="focusElement"
                                                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                inputClass="nice-font inputtext-param text-center text-5xl"  />
                                                <label for="radiationSetpoint" class="mr-2">{{ this.getDiagDataPeriods(index, 'radiationSetpoint').label }}</label>
                                            <span class="p-inputgroup-addon nice-font text-3xl">{{ this.getDiagDataPeriods(index, 'radiationSetpoint').unit }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataPeriods(index, 'radiationEnable').value" /><span class="ml-2">{{ this.getDiagDataPeriods(index, 'radiationEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-6 mt-3">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="radiationOnDelay" ref="radiationOnDelay" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataPeriods(index, 'radiationOnDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="radiationOnDelay" class="mr-2">{{ this.getDiagDataPeriods(index, 'radiationOnDelay').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-6 mt-3">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputMask id="radiationOffDelay" ref="radiationOffDelay" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataPeriods(index, 'radiationOffDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="radiationOffDelay" class="mr-2">{{ this.getDiagDataPeriods(index, 'radiationOffDelay').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <div class="formgrid grid p-1">
                            <div class="field col">
                                <div class="flex justify-content-center mt-2">
                                    <Button label="Periodenbezeichnung ändern" @click="toogleNameDiag(this.getDiagDataPeriods(index, 'periodName'))" />
                                </div>
                            </div>
                        </div>
                        
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagTogglePeriods" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('periods')" :disabled="periodCheck" />
                    </div>
                </div>
            </template>
        </Dialog>
        <OverlayPanel ref="misc" appendTo="body" @show="focusElement" :dismissable="false">
        </OverlayPanel>
        <Dialog
            v-model:visible="showDiagMisc"
            @show="focusElement"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '40vw' }"
        >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i><span class="subtext mt-1">{{ moduleDepartment.key }} {{ moduleDepartment.label }} ⚡ Allgemeine Einstellungen</span></h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('misc')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView :scrollable="true">
                    <TabPanel header="Programmeinstellungen">
                        <Panel header="Belichtung Hauptschalter">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' />Der Hauptschalter gibt unabhängig von den Perioden die Belichtung übergeordnet frei oder sperrt sie.`"></i>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('enableIllumination').value" /><span class="ml-2">Belichtung aktivieren</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Lichtführung" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' />Der Schirmdämpfungswert ist in Prozent anzugeben und gibt an, wie sehr der Schirm die Globalstrahlung dämpft, wenn er komplett geschlossen ist.<br/>Die Leistung wird in Watt angegeben und spiegelt die Gesamtleistung der Belichtung dieses Moduls.`"></i>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="screenLightFactor" ref="screenLightFactor" :min="0" :max="100"
                                            v-model="getDiagDataCfg('screenLightFactor').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('screenLightFactor').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="screenLightFactor" class="mr-2">{{ getDiagDataCfg('screenLightFactor').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="power" ref="power" :min="-50" :max="100"
                                            v-model="getDiagDataCfg('power').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('power').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="power" class="mr-2">{{ getDiagDataCfg('power').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Sperrzeit" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' />Die Sperrzeit sorgt nach einer Belichtungsphase für eine Minimum-Pause, welche eingehalten werden muss um z.B. die Leuchten zu schonen.`"></i>
                                <i class="fi fi-rr-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="forceoffTime" ref="forceoffTime" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('forceoffTime').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="forceoffTime" class="mr-2">{{ getDiagDataCfg('forceoffTime').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Service-Parameter" v-if="isMaster">
                        <Panel header="Leuchten" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-heat bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="daliLampCount" ref="daliLampCount" :min="1" :max="24"
                                            v-model="getDiagDataCfg('daliLampCount').value" mode="decimal"
                                            :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="daliLampCount" class="mr-2">{{ getDiagDataCfg('daliLampCount').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Regler-Einstellungen" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-heat bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="zeroPoint" ref="zeroPoint" :min="0" :max="100"
                                            v-model="getDiagDataCfg('zeroPoint').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('zeroPoint').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="zeroPoint" class="mr-2">{{ getDiagDataCfg('zeroPoint').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="sensorFilterTime" ref="sensorFilterTime" :min="0" :max="100"
                                            v-model="getDiagDataCfg('sensorFilterTime').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('sensorFilterTime').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="sensorFilterTime" class="mr-2">{{ getDiagDataCfg('sensorFilterTime').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="DALI-Einstellungen" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-heat bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="daliCmdDelay" ref="daliCmdDelay" :min="0" :max="10000"
                                            v-model="getDiagDataCfg('daliCmdDelay').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('daliCmdDelay').unit}`"
                                            :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="daliCmdDelay" class="mr-2">{{ getDiagDataCfg('daliCmdDelay').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('enableDali').value" /><span class="ml-2">DALI aktivieren</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Sensorskalierung" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-cog bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="parSensInMin" ref="parSensInMin" :min="0" :max="32767"
                                                v-model="getDiagDataCfg('parSensInMin').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="parSensInMin" class="mr-2">Sensor IN Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="parSensOutMin" ref="parSensOutMin" :min="-1000" :max="1000"
                                                v-model="getDiagDataCfg('parSensOutMin').value"
                                                :suffix="` ${this.getDiagDataCfg('parSensOutMin').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="parSensOutMin" class="mr-2">Sensor OUT Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="parSensInMax" ref="parSensInMax" :min="0" :max="32767"
                                                v-model="getDiagDataCfg('parSensInMax').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="parSensInMax" class="mr-2">Sensor IN Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="parSensOutMax" ref="parSensOutMax" :min="-100000" :max="100000"
                                                v-model="getDiagDataCfg('parSensOutMax').value"
                                                :suffix="` ${this.getDiagDataCfg('parSensOutMax').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="parSensOutMax" class="mr-2">Sensor OUT Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagToggleMisc" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('misc')" />
                    </div>
                </div>
            </template>
        </Dialog>
        <Dialog 
            v-model:visible="showDiagPhotons"
            @show="focusElement"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '50vw' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ Photonenmixer Leuchtenkonfiguration
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="diagTogglePhotons" @keydown.enter="saveDiag('photons')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true">
                    <TabPanel v-for="(element, index) in this.nodeDataPhotons.slice(0, getModuleValue('OUT_LampCount').value)" :key="index">
                        <!-- <Panel :header="returnParameter(element, 'parameter', 'PeriodName').value + ' - Sollwert'"> -->
                        <template #header>
                            <div class="flex align-items-center gap-2">
                                <span class="white-space-nowrap" :class="this.getDiagDataPhotons(index, 'lampEnable').value ? '' : 'line-through'">{{ index+1 }}: {{ this.getDiagDataPhotons(index, 'lampName').value }}</span>
                            </div>
                        </template>
                        <Panel>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <div class="flex justify-content-center">
                                        <p class="text-4xl fg-yellow">{{ this.getDiagDataPhotons(index, 'lampName').value }}</p>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <div class="flex justify-content-center">
                                        <p class="text-4xl fg-yellow">{{ this.getDiagDataPhotons(index, 'lampId').value }}</p>
                                    </div>
                                </div>
                            </div>
                        </Panel>  
                        <Panel :header="`${this.getDiagDataPhotons(index, 'lampName').value} - Leuchten-Hauptschalter`" class="mt-2">
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="flex justify-content-end flex-wrap">
                                        <div class="flex align-items-center justify-content-center mb-3 mr-3">
                                            <span class="mr-2">Leuchte aktivieren</span><InputSwitch v-model="this.getDiagDataPhotons(index, 'lampEnable').value" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>    
                        <Panel :header="`${this.getDiagDataPhotons(index, 'lampName').value} - Konfiguration Spektrum/Leuchtkraft`" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Der <b>Photonenmixer</b> ermöglicht bei deaktiviertem Preset eine individuelle Spektrumskonfiguration pro Leuchte. Ist der Preset-Schalter aktiviert, so kann man eines der vordefinierten Presets auswählen und zuordnen.`"></i>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="flex justify-content-end flex-wrap">
                                        <div class="flex align-items-center justify-content-center mt-3 mb-3 mr-3">
                                            <span class="mr-2">Photonenmix-Presets verwenden</span><InputSwitch v-model="this.getDiagDataPhotons(index, 'lampPhotonUse').value" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="this.getDiagDataPhotons(index, 'lampPhotonUse').value">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center mt-3 mb-3">
                                            <Dropdown v-model="this.getDiagDataPhotons(index, 'lampPhotonPreset').value"
                                                :options="this.photonPreset" optionLabel="label" optionValue="value" 
                                                placeholder="Preset auswählen" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="mt-3">
                                <div class="formgrid grid">
                                    <div class="field col-6">
                                        <span class="p-float-label">
                                            <InputNumber id="lampChannel0Level" ref="lampChannel0Level" :min="0" :max="100"
                                                v-model.number="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[0].daliLevel`).value" autofocus @focus="focusElement"
                                                :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                                inputClass="nice-font inputtext-param text-center text-5xl" class="bg-cobalt fg-yellow" />
                                            <Slider v-model="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[0].daliLevel`).value" />
                                            <label for="lampChannel0Level" class="mr-2 fg-yellow">450+470nm blau</label>
                                        </span>
                                    </div>
                                    <div class="field col-6">
                                        <span class="p-float-label">
                                            <InputNumber id="lampChannel1Level" ref="lampChannel1Level" :min="0" :max="100"
                                                v-model.number="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[1].daliLevel`).value" autofocus @focus="focusElement"
                                                :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                                inputClass="nice-font inputtext-param text-center text-5xl bg-lightGray fg-black" />
                                            <Slider v-model="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[1].daliLevel`).value" />
                                            <label for="lampChannel1Level" class="mr-2 fg-black">6500K weiß</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="formgrid grid">
                                    <div class="field col-6">
                                        <span class="p-float-label">
                                            <InputNumber id="lampChannel2Level" ref="lampChannel2Level" :min="0" :max="100"
                                                v-model.number="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[2].daliLevel`).value" autofocus @focus="focusElement"
                                                :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                                inputClass="nice-font inputtext-param text-center text-5xl bg-red" />
                                            <Slider v-model="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[2].daliLevel`).value" />
                                            <label for="lampChannel2Level" class="mr-2">660nm rot</label>
                                        </span>
                                    </div>
                                    <div class="field col-6">
                                        <span class="p-float-label">
                                            <InputNumber id="lampChannel3Level" ref="lampChannel3Level" :min="0" :max="100"
                                                v-model.number="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[3].daliLevel`).value" autofocus @focus="focusElement"
                                                :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                                inputClass="nice-font inputtext-param text-center text-5xl bg-crimson" />
                                            <Slider v-model="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[3].daliLevel`).value" />
                                            <label for="lampChannel3Level" class="mr-2">730nm "far red"</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="formgrid grid">
                                    <div class="field col-6">
                                        <span class="p-float-label">
                                            <InputNumber id="lampChannel4Level" ref="lampChannel4Level" :min="0" :max="100"
                                                v-model.number="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[4].daliLevel`).value" autofocus @focus="focusElement"
                                                :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                                inputClass="nice-font inputtext-param text-center text-5xl bg-blue fg-yellow" />
                                            <Slider v-model="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[4].daliLevel`).value" />
                                            <label for="lampChannel4Level" class="mr-2 fg-yellow">8800K blau/weiß</label>
                                        </span>
                                    </div>
                                    <div class="field col-6">
                                        <span class="p-float-label">
                                            <InputNumber id="lampChannel5Level" ref="lampChannel5Level" :min="0" :max="100"
                                                v-model.number="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[5].daliLevel`).value" autofocus @focus="focusElement"
                                                :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                                inputClass="nice-font inputtext-param text-center text-5xl bg-green" />
                                            <Slider v-model="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[5].daliLevel`).value" />
                                            <label for="lampChannel5Level" class="mr-2">505nm verde</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="formgrid grid">
                                    <div class="field col-6">
                                        <span class="p-float-label">
                                            <InputNumber id="lampChannel6Level" ref="lampChannel6Level" :min="0" :max="100"
                                                v-model.number="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[6].daliLevel`).value" autofocus @focus="focusElement"
                                                :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                                inputClass="nice-font inputtext-param text-center text-5xl bg-lightBlue fg-black" />
                                            <Slider v-model="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[6].daliLevel`).value" />
                                            <label for="lampChannel6Level" class="mr-2 fg-black">EQ-W</label>
                                        </span>
                                    </div>
                                    <div class="field col-6">
                                        <span class="p-float-label">
                                            <InputNumber id="lampChannel7Level" ref="lampChannel7Level" :min="0" :max="100"
                                                v-model.number="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[7].daliLevel`).value" autofocus @focus="focusElement"
                                                :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false" suffix="%"
                                                inputClass="nice-font inputtext-param text-center text-5xl bg-darkAmber" />
                                            <Slider v-model="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[7].daliLevel`).value" />
                                            <label for="lampChannel7Level" class="mr-2">3000K</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="formgrid grid p-1">
                                    <div class="field col-3"></div>
                                    <div class="field col-6">
                                        <div class="flex justify-content-center mt-3">
                                            <Button label="Auf alle Leuchten in der Abteilung kopieren" @click="copyChannelsConfirmDiagOpen(index)" />
                                        </div>
                                    </div>
                                    <div class="field col-3"></div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataPhotons(index, 'lampName').value} - Serviceparameter`" class="mt-2" v-if="isMaster">
                            <template #icons>
                                <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> Das Strahlungsprogramm schaltet die Belichtung in der freigegebenen Periode unterhalb des <b>Strahlung Sollwert</b> (Globalstrahlung Außen) ein. Die Schirmdämpfung (Allgemein) gibt die Lichtdämpfung in % durch den Schattierschirm an.`"></i>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            
                            <div class="formgrid grid">
                                <div class="field col-3 mt-3">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputNumber id="lampGroup" ref="lampGroup" :min="0" :max="100"
                                                    v-model="this.getDiagDataPhotons(index, 'lampGroup').value" autofocus @focus="focusElement"
                                                    :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                                    inputClass="nice-font inputtext-param text-lg"  />
                                                <label for="lampGroup" class="mr-2">{{ this.getDiagDataPhotons(index, 'lampGroup').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-3 mt-3">
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <InputNumber id="lampPower" ref="lampPower" :min="0" :max="10000"
                                                    v-model="this.getDiagDataPhotons(index, 'lampPower').value" autofocus @focus="focusElement"
                                                    :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                                    inputClass="nice-font inputtext-param text-lg"  />
                                                <label for="lampPower" class="mr-2">{{ this.getDiagDataPhotons(index, 'lampPower').label }}</label>
                                                <span class="p-inputgroup-addon nice-font text-lg">{{ this.getDiagDataPhotons(index, 'lampPower').unit }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div v-for="(element, subIndex) in this.daliChannels" :key="subIndex">
                                <div class="formgrid grid">
                                    <div class="field col-3 flex justify-content-center align-items-center">
                                        <p class="text-xl">{{ element.label }}</p>
                                    </div>
                                    <div class="field col-2">
                                        <div class="field">
                                            <div class="p-inputgroup">
                                                <span class="p-float-label">
                                                    <InputNumber id="lampDaliBridge" ref="lampDaliBridge" :min="0" :max="100"
                                                        v-model="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[${subIndex}].daliBridge`).value" autofocus @focus="focusElement"
                                                        :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                                        inputClass="nice-font inputtext-param text-lg"  />
                                                    <label for="lampDaliBridge" class="mr-2">DALI-Bridge</label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field col-2">
                                        <div class="field">
                                            <div class="p-inputgroup">
                                                <span class="p-float-label">
                                                    <InputNumber id="lampDaliChannel" ref="lampDaliChannel" :min="0" :max="254"
                                                        v-model="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[${subIndex}].daliChannel`).value" autofocus @focus="focusElement"
                                                        :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                                        inputClass="nice-font inputtext-param text-lg"  />
                                                    <label for="lampDaliChannel" class="mr-2">DALI-Channel</label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field col-2">
                                        <div class="field">
                                            <div class="p-inputgroup">
                                                <span class="p-float-label">
                                                    <InputNumber id="lampDaliGroup" ref="lampDaliGroup" :min="0" :max="254"
                                                        v-model="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[${subIndex}].daliGroup`).value" autofocus @focus="focusElement"
                                                        :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                                        inputClass="nice-font inputtext-param text-lg"  />
                                                    <label for="lampDaliGroup" class="mr-2">DALI-Group</label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field col-3">
                                        <div class="field">
                                            <span class="p-float-label">
                                                <div class="field col flex justify-content-center">
                                                    <div class="flex align-items-center">
                                                        <InputSwitch v-model="this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[${subIndex}].daliEnable`).value" /><span class="ml-2">Kanal aktivieren</span>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center mb-3">
                                            <InputSwitch v-model="this.getDiagDataPhotons(index, 'lampDaliWrite').value" /><span class="ml-2">DALI-Parameter beim Speichern senden</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col-3"></div>
                                <div class="field col-6">
                                    <div class="flex justify-content-center mt-3">
                                        <Button label="ID ändern" @click="toogleLampIdDiag(this.getDiagDataPhotons(index, 'lampId'))" />
                                    </div>
                                </div>
                                <div class="field col-3"></div>
                        </div>
                        </Panel>
                        <div class="formgrid grid p-1">
                            <div class="field col-3"></div>
                            <div class="field col-6">
                                <div class="flex justify-content-center mt-3">
                                    <Button label="Leuchtennamen ändern" @click="toogleLampNameDiag(this.getDiagDataPhotons(index, 'lampName'))" />
                                </div>
                            </div>
                            <div class="field col-3"></div>
                        </div>
                        
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagTogglePhotons" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('photons')" />
                    </div>
                </div>
            </template>
        </Dialog>

        <div class="menu-overlay">
            <Button v-if="editMode" icon="pi pi-bars"
                class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber handle" title="Bewegen"></Button>
            <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber" title="Menü"
                @click="toggle"></Button>
            <Menu :id="'boxMenu_' + 1" ref="menu" :model="items" :popup="true" :baseZIndex="100" />
        </div>
        <div v-if="!isLoading">
            <div class="flex justify-content-between align-items-center p-0 flex-wrap">
                <div class="flex justify-content-start align-items-center p-0">
                    <div v-show="true" class="hidden md:inline-flex social-icon">
                        <i :class="moduleData.metadata.icon + ' fs-xxlarge icon-md'"></i>
                    </div>
                    <div class="flex mt-1 ml-2">
                        <widget-mini-icon label="Lichtkreis 1" :value="this.getModuleValue('OUT_LampGrp1')" icon="fi-rr-bulb"></widget-mini-icon>
                        <widget-mini-icon label="Lichtkreis 2" :value="this.getModuleValue('OUT_LampGrp2')" icon="fi-rr-bulb"></widget-mini-icon>
                    </div>
                    <div class="flex flex-column mt-3">

                    </div>
                </div>
                <div class="flex justify-content-end align-items-center p-0">
                    <widget-header :value="this.getModuleValue('OUT_PAR')" :moduleData="this.moduleData" @showChart="showChart('value1')" :fgColor="fontColor" />
                </div>
            </div>

            <div class="stats flex mt-1">
                <!-- <div class="left flex flex-column"> -->
                <div class="left">
                    <ul class="widget-list">
                        <Widget_rowValues2 :value1="this.getModuleValue('OUT_Runtime')" :value2="this.getModuleValue('OUT_Request')"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="this.getModuleValue('OUT_RuntimeTotal')" :value2="this.getModuleValue('OUT_CurrentForceoffTime')"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="this.getModuleValue('OUT_StartsToday')" :value2="this.getModuleValue('OUT_StartsYesterday')"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="this.getModuleValue('OUT_LastStartDt')" :value2="this.getModuleValue('OUT_LastEndDt')"></Widget_rowValues2>
                    </ul>
                </div>
                <div class="right flex flex-column">
                    <div class="flex flex-wrap">
                        <div class="flex-1 align-items-center justify-content-center mt-3">
                            <button-enat title="Perioden" subtitle="Einstellungen Belichtungsperioden" icon="fi fi-rr-time-twenty-four" fgColor="yellow" bgColor="darkAmber"
                                :badge="periodIndex + 1" @click="toggleOP($event, 'periods')" >
                            </button-enat>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mt-3">
                            <button-enat title="Allgemein" subtitle="Alarme, Meldungen" icon="fi fi-rr-settings" fgColor="yellow" bgColor="darkSteel"
                                @click="toggleOP($event, 'misc')" >
                            </button-enat>
                        </div>
                    </div>
                    <div class="flex flex-wrap mt-2">
                        <div class="flex-1 align-items-center justify-content-center">
                            <button-enat title="Photonenmixer" subtitle="Einstellungen Spektren" icon="fi fi-rr-settings-sliders" fgColor="darkGrayBlue" bgColor="lightAmber"
                               @click="toggleOP($event, 'photons')" >
                            </button-enat>
                        </div>
                    </div>
                    <div class="flex flex-wrap mt-2">
                        <div class="flex-1 align-items-center justify-content-center mt-1">
                            <valuebox-enat title="aktive Periode" :value="getModuleValue('OUT_AktPeriode.periodName').value" :moduleData="moduleData" icon="fi fi-rr-time-twenty-four" />
                        </div>
                    </div>
                </div>
            </div>
            <textbox-enat title="Status" :value="currentStatusStr" :moduleData="moduleData" icon="fi fi-rr-comment-alt" />
        </div>
        <Dialog 
            v-model:visible="showDiagName"
            :closable="false"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '450px' }"
            :modal="true"
            class="p-fluid"
        >
            <template #header>
                <p class="nice-font"><i class="fi fi-rr-text"></i><span class="pl-1 mt-1">Bezeichnung ändern</span></p>
            </template>
            <div @keydown.esc="hideNameDiag" @keydown.enter="saveNameDiag(changeDiagName)">
                <Panel>
                    <div class="grid p-fluid">
                        <div class="col-12 pl-5 pr-5">
                            <span class="p-float-label mr-2">
                                <InputText id="periodName" ref="periodName" v-model="changeDiagName.value" locale="de-DE" class="nice-font inputtext-param text-center text-2xl" />
                                <label for="periodName" class="mr-2">Bezeichnung</label>
                            </span>
                        </div>
                    </div>
                </Panel>
            </div>
            <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideNameDiag" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveNameDiag(changeDiagName)" />
            </template>
        </Dialog>
        <Dialog 
            v-model:visible="showDiagLedName"
            :closable="false"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '450px' }"
            :modal="true"
            class="p-fluid"
        >
            <template #header>
                <p class="nice-font"><i class="fi fi-rr-text"></i><span class="pl-1 mt-1">Leuchtenbezeichnung ändern</span></p>
            </template>
            <div @keydown.esc="hideLampNameDiag" @keydown.enter="saveLampNameDiag(changeDiagLedName)">
                <Panel>
                    <div class="grid p-fluid">
                        <div class="col-12 pl-5 pr-5">
                            <span class="p-float-label mr-2">
                                <InputText id="lampName" ref="lampName" v-model="changeDiagLedName.value" locale="de-DE" class="nice-font inputtext-param text-center text-2xl" />
                                <label for="lampName" class="mr-2">Leuchten-Name</label>
                            </span>
                        </div>
                    </div>
                </Panel>
            </div>
            <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideLampNameDiag" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveLampNameDiag(changeDiagLedName)" />
            </template>
        </Dialog>
        <Dialog 
            v-model:visible="showDiagLedId"
            :closable="false"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '450px' }"
            :modal="true"
            class="p-fluid"
        >
            <template #header>
                <p class="nice-font"><i class="fi fi-rr-text"></i><span class="pl-1 mt-1">Leuchtenbezeichnung ändern</span></p>
            </template>
            <div @keydown.esc="hideLampIdDiag" @keydown.enter="saveLampIdDiag(changeDiagLedId)">
                <Panel>
                    <div class="grid p-fluid">
                        <div class="col-12 pl-5 pr-5">
                            <span class="p-float-label mr-2">
                                <InputText id="lampId" ref="lampId" v-model="changeDiagLedId.value" locale="de-DE" class="nice-font inputtext-param text-center text-2xl" />
                                <label for="lampId" class="mr-2">Leuchten-ID</label>
                            </span>
                        </div>
                    </div>
                </Panel>
            </div>
            <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideLampIdDiag" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveLampIdDiag(changeDiagLedId)" />
            </template>
        </Dialog>
        <Dialog
            v-model:visible="copyChannelsConfirmDiag"
            :style="{ width: '450px' }"
            header="Bestätigung"
            :modal="true"
            >
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="ioDialogData">Sollen die aktuellen Kanaleinstellungen wirklich auf alle {{ this.nodeDataPhotons.length }} Leuchten übertragen werden?</span>
            </div>
            <template #footer>
                <Button
                label="Nein"
                icon="pi pi-times"
                class="p-button-text"
                @click="copyChannelsConfirmDiagClose()"
                />
                <Button
                label="Ja"
                icon="pi pi-check"
                class="p-button-text"
                @click="copyLevelToAll(this.copyChannelIndex)"
                />
            </template>
        </Dialog>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <dialog-chart-single :icon="getModuleValue('OUT_PAR').icon" :node="getModuleValue('OUT_PAR')" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import { mapGetters, mapActions } from 'vuex';
import helpers from '@/helpers';
import { parseNode, setOpcValueToast } from '@/helpers';
import SocketioService from '@/services/socketioService.js';
import Widget_rowValues2 from '@/components/fragments/widget_rowValues2.vue';
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
import widgetMiniIcon from '@/components/fragments/widgetMiniIcon.vue';
// import widgetTimeSet from '@/components/fragments/widgetTimeSet.vue';

export default defineComponent({
    name: 'widgetIlluminationControllerDali',
    setup() { },
    components: {
        Widget_rowValues2,
        dialogChartSingle,
        widgetMiniIcon,
    },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        createdLayout: {
            type: Boolean,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        createdLayout() {
            this.watchCreatedLayout();
        },
        readyLayout() {
            this.watchLayout();
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
        // nodeEntry: {
        //     handler: function(entry) {
        //         // this.filterParameters();
        //         // if (Array.isArray(entries) && entries.length > 0) {
        //         //     this.loading = false;
        //         // } else {
        //         //     this.loading = true;
        //         // }
        //     },
        //     deep: true
        // }
    },
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getIoTree: 'getIoTree',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getModuleFetched: 'opcua/getModuleFetched',
            isMaster: 'auth/isMaster',
        }),
        isLoading: function() {
            if (!this.moduleData || !this.getModuleFetched(this.moduleHash) || this.loading) return true;
            return false;
        },
        
        connectedModule: function () {
            if (this.content.data.module) {
                return this.content.data.module;
            }
            return null;
        },
        moduleData: function () {
            if (this.connectedModule) {
                const module = this.getModule(this.connectedModule);
                if (module) return module;
            }
            return null;
        },
        moduleHash: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.source.server !== null && this.moduleData.source.identifier !== null) {
                    return `${this.moduleData.source.server}@${this.moduleData.source.identifier}`
                }
            }
            return null;
        },
        moduleDepartment: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.department !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.department);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        moduleCategory: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.category !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.category);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else if (this.moduleData !== null && this.moduleData.metadata.label) {
                return this.moduleData.metadata.label;
            } else {
                return store.getters.getNoval;
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        componentStyle: function () {
            if (this.content.style && this.content.style !== 'standard') {
                return 'box ptrn-' +  this.content.style.pattern + ' bg-' + this.content.style.background + ' fg-' + this.content.style.foreground;
            } else {
                return '';
            }
        },
        fontColor: function () {
            if (this.content.style && this.content.style.foreground) {
                return `fg-${this.content.style.foreground}`;
            }
            return 'gray';
        },
        alarmStatus: function () {
            // if (this.nodeEntry !== undefined && this.nodeEntry.children !== undefined) {
            //     let iCol;
            //     const index = findIndexByDisplayName(this.nodeEntry.children, 'AlarmStatus');
            //     if (this.nodeEntry.children[index].value.length > 0) {
            //         iCol = ' alarm';
            //     } else {
            //         iCol = '';
            //     }
            //     return iCol;
            // } else {
            //     return 'mif-blocked';
            // }
            return '';
        },
        orientationStr1: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 1);
        },
        orientationStr2: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 2);
        },
        currentStatusStr: function () {
            return this.getModuleValue('OUT_StatusStr').value;
        },
        currentPositionFloat1: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_1_Aktuell').value);
        },
        currentPositionFloat2: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_2_Aktuell').value);
        },
        icon: function () {
            if (this.alarmStatus === 0) {
                return this.moduleData.metadata.icon
            } else {
                return `${this.moduleData.metadata.icon} fg-red pulse-loop cursor-pointer`;
            }
        },
        operateState: function () {
            if (this.getModuleValue('OUT_Lamp').value === true) return '🟢Ein';
            else return '🔴Aus';
        },
        operateStateGrp1: function () {
            if (this.getModuleValue('OUT_LampGrp1').value === true) return '🟢Ein';
            else return '🔴Aus';
        },
        operateStateGrp2: function () {
            if (this.getModuleValue('OUT_LampGrp2').value === true) return '🟢Ein';
            else return '🔴Aus';
        },
        periodIndex: function () {
            return this.getModuleValue('OUT_AktPeriode.periodIndex').value;
        },
        photonPreset: function () {
            let arr = [];
            for (let i=0; i<=15; i++) {
                const channel = {
                    label: `[${i+1}] ` + this.getModuleValue(`IN_PhotonMix[${i}].presetName`).value,
                    value: i
                }
                arr.push(channel);
            }
            //console.log(this.getModuleValue('IN_PhotonMix[0].presetName'));
            return arr;
        },
        periodCheck: function () {
            if (this.nodeDataPeriods.length > 0) {
                let cnt = 0;
                this.nodeDataPeriods.forEach((period) => {
                    const result = period.find(entry => entry.displayName === 'periodEnable');
                    if (result) {
                        if (result.value === true) cnt+=1;
                    }
                });
                if (cnt > 0) return false;
            }
            return true;
        },
    },
    data() {
        return {
            loading: false,
            showChart1: false,
            saveWait: false,
            dialogLoading: false,
            entryDialog: false,
            valveDialog: false,
            ioDialogData: {},
            ioObj: {},
            timeSetGrp: null,
            nodeData: [],
            nodeDataOld: [],
            nodeDataCfg: [],
            nodeDataCfgOld: [],
            nodeDataPeriods: [],
            nodeDataPeriodsOld: [],
            nodeDataPhotons: [],
            nodeDataPhotonsOld: [],
            diagPeriod: 0,
            showDiagName: false,
            showDiagLedName: false,
            showDiagLedId: false,
            changeDiagName: null,
            changeDiagLedName: null,
            changeDiagLedId: null,
            copyChannelsConfirmDiag: false,
            copyChannelIndex: null,
            photonMixerPresets: [],
            daliChannels: [
                { label: `Kanal 1`, value: 0 },
                { label: `Kanal 2`, value: 1 },
                { label: `Kanal 3`, value: 2 },
                { label: `Kanal 4`, value: 3 },
                { label: `Kanal 5`, value: 4 },
                { label: `Kanal 6`, value: 5 },
                { label: `Kanal 7`, value: 6 },
                { label: `Kanal 8`, value: 7 },
            ],
            currentGrp: {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            },
            node: null,
            lala: null,
            showDiagPeriods: false,
            showDiagPhotons: false,
            showDiagMisc: false,
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            items: [],
            items1: [
                {
                    label: 'Perioden',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'periods');
                    },
                },
                {
                    label: 'Einstellungen',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editValve(this.content);
                        this.toggleOP(null, 'misc');
                    },
                },
                {
                    label: 'Photonenmixer',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'photons');
                    },
                },
            ],
            items2: [
                {
                    separator: true,
                },
                {
                    label: 'Einstellungen',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
        };
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.checkEditMode();
        if (!this.getModuleFetched(this.moduleHash)) {
            this.loadModule(this.connectedModule).then((module) => {
                const moduleHash = `${module.source.server}@${module.source.identifier}.*`;
                SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
                    if (!err && response) {
                        if (Array.isArray(response) && response.length > 0) {
                            this.setNodesFull(response);
                        }
                    }
                }).then(() => { 
                    if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : component ready!`);
                    this.setModuleFetched(this.moduleHash);
                    this.loading = false;
                });
            });
        } else {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            loadNode: 'opcua/loadNode',
            loadModule: 'opcua/loadModule',
            setNodesFull: 'opcua/setNodesFull',
            setModuleFetched: 'opcua/setModuleFetched',
        }),
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        getModuleValue(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            return this.getNode(key);
        },
        getModuleValueStr(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            const node = this.getNode(key);
            if (typeof node.unit === 'string' && node.unit.length > 0) {
                return `${node.value}${node.unit}`;
            }
            return `${node.value}`;
        },
        getDiagData(key) {
            if (this.nodeData.length > 0) {
                const result = this.nodeData.find(entry => entry.key2 === key);
                return result;
            }
            return null;
        },
        getDiagDataPeriods(index, displayName) {
            if (this.nodeDataPeriods.length > 0 && index >= 0) {
                const result = this.nodeDataPeriods[index].find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataCfg(displayName) {
            if (this.nodeDataCfg.length > 0 && displayName) {
                const result = this.nodeDataCfg.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataPhotons(index, displayName) {
            if (this.nodeDataPhotons.length > 0 && index >= 0) {
                const result = this.nodeDataPhotons[index].find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataPhotonsChannel(index, arrayKey) {
            if (this.nodeDataPhotons.length > 0 && index >= 0) {
                const result = this.nodeDataPhotons[index].find(entry => entry.arrayKey === arrayKey);
                return result;
            }
            return null;
        },
        watchCreatedLayout() {
            // this.loading = true;
        },
        watchLayout() {
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode) {
                this.items = [...this.items1, ...this.items2];
            } else {
                this.items = [...this.items1];
            }
        },
        clearVals() {
            this.nodeDataCfg = [];
            this.nodeDataCfgOld = [];
            this.nodeDataPeriods = [];
            this.nodeDataPeriodsOld = [];
            this.nodeDataPhotons = [];
            this.nodeDataPhotonsOld = [];
            this.saveWait = false;
            this.dialogLoading = false;
            this.diagPeriod = 0;
        },
        updateMap() {
            // this.node = helpers.mapNodes(_.cloneDeep(this.nodeEntry.children), null);
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    this.node.set(key, {...child, value: child.value});
                }
            }
        },
        currentMap() {
            let map = new Map();
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    map.set(key, {...child, value: child.value});
                }
                return map;
            }
            return null;
        },
        getParameter(lvl1, lvl2, index) {
            let parameter = null;
            if (!lvl1) return false;
            if (!lvl2) lvl2 = '';
            if (!isNaN(index)) {
                parameter = this.node.get(lvl1 + '[' + index + '].' + lvl2);
            } else {
                parameter = this.node.get(lvl1 + lvl2);
            }
            return parameter;
        },
        returnChild(childStr) {
            if (typeof childStr == 'string' && this.nodeEntry !== undefined) {
                return helpers.returnChild(this.nodeEntry, childStr);
            } else {
                return null;
            }
        },
        returnParameter(node, key, parameter) {
            return helpers.returnParameter(node, key, parameter);
        },
        returnParameterValue(node, key, parameter, unit) {
            return helpers.returnParameterValue(node, key, parameter, unit);
        },
        timeDialog(time) {
            console.log(time);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.this.getModuleValueStatusIOTree();
            // this.getOPCAppTree();
        },
        toggleOP(event, grp) {
            if (grp === 'periods') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG.periodsIllumination*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            let tmpNodes = [];
                            tmpNodes = JSON.parse(JSON.stringify(response));
                            tmpNodes.forEach((entry) => {
                                if (entry.child1) {
                                    const child = JSON.parse(entry.child1)
                                    if (child.index >= 0) {
                                        if (!Array.isArray(this.nodeDataPeriods[child.index])) {
                                            this.nodeDataPeriods[child.index] = [];
                                        }
                                        this.nodeDataPeriods[child.index].push(entry);
                                    }
                                }
                                // console.log(entry);
                            });
                            this.nodeDataPeriodsOld = JSON.parse(JSON.stringify(this.nodeDataPeriods));
                            console.log(this.nodeDataPeriods);
                            console.log(this.nodeDataPeriodsOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                    }
                }).then(() => {
                    this.showDiagPeriods = true;
                });        
            } else if (grp === 'photons') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG_DaliChannels*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            let tmpNodes = [];
                            tmpNodes = JSON.parse(JSON.stringify(response));
                            tmpNodes.forEach((entry) => {
                                if (entry.child0) {
                                    const child = JSON.parse(entry.child0)
                                    if (child.index >= 0) {
                                        if (!Array.isArray(this.nodeDataPhotons[child.index])) {
                                            this.nodeDataPhotons[child.index] = [];
                                        }
                                        this.nodeDataPhotons[child.index].push(entry);
                                    }
                                }
                                // console.log(entry);
                            });
                            this.nodeDataPhotonsOld = JSON.parse(JSON.stringify(this.nodeDataPhotons));
                            console.log(this.nodeDataPhotons);
                            console.log(this.nodeDataPhotonsOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                    }
                }).then(() => {
                    this.showDiagPhotons = true;
                }); 
            } else if (grp === 'misc') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataCfg = JSON.parse(JSON.stringify(response));
                            this.nodeDataCfgOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataCfg);
                            console.log(this.nodeDataCfgOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagMisc = true;
                }); 
            }
        },
        diagTogglePeriods() {
            if (this.showDiagPeriods) {
                this.showDiagPeriods = false;
            } else {
                this.showDiagPeriods = true;
            }
            this.hideOP();
        },
        diagTogglePhotons() {
            if (this.showDiagPhotons) {
                this.showDiagPhotons = false;
            } else {
                this.showDiagPhotons = true;
            }
            this.hideOP();
        },
        diagToggleMisc() {
            if (this.showDiagMisc) {
                this.showDiagMisc = false;
            } else {
                this.showDiagMisc = true;
            }
            this.hideOP();
        },
        hideOP() {
            this.clearVals();
        },
        saveDiag(diag) {
            if (diag) {
                const saveArray = [];
                this.dialogLoading = true;
                this.saveWait = true;

                if (diag === 'periods' && !this.periodCheck) {
                    // console.log(this.nodeDataPeriods);
                    // console.log(this.nodeDataPeriodsOld);
                    const flatHzg = this.nodeDataPeriods.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataPeriodsOld.flatMap(innerArr => innerArr);
                    // console.log(flatHzg);
                    // console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'photons') {
                    // console.log(this.nodeDataPhotons);
                    // console.log(this.nodeDataPhotonsOld);
                    const flatHzg = this.nodeDataPhotons.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataPhotonsOld.flatMap(innerArr => innerArr);
                    // console.log(flatHzg);
                    // console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'misc') {
                    // console.log(this.nodeDataCfg);
                    // console.log(this.nodeDataCfgOld);
                    this.nodeDataCfg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = this.nodeDataCfgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                }
                
                // console.log(saveArray);
                if (saveArray.length > 0) {
                    SocketioService.setOpcValue(saveArray, (err, response) => {
                        if (err) {
                            console.log(err);
                        } else {
                            console.log(response);
                            setOpcValueToast(response, this.$root);
                            this.dialogLoading = false;
                            if (diag === 'periods') this.showDiagPeriods = false;
                            if (diag === 'misc') this.showDiagMisc = false;
                            if (diag === 'photons') this.showDiagPhotons = false;
                            this.hideOP();
                        }
                    });
                } else {
                    if (diag === 'periods' && !this.periodCheck) {
                        this.saveDiagNoChanges();
                        this.showDiagPeriods = false;
                    } else if (diag === 'periods' && this.periodCheck) {
                        this.saveDiagNoPeriods();
                    } else if (diag === 'misc') {
                        this.saveDiagNoChanges();
                        this.showDiagMisc = false;
                    } else if (diag === 'photons') {
                        this.saveDiagNoChanges();
                        this.showDiagPhotons = false;
                    }
                }
            }
        },
        saveDiagNoChanges() {
            setOpcValueToast(null, this.$root);
            this.dialogLoading = false;
            this.hideOP();
        },
        saveDiagNoPeriods() {
            this.$toast.add({
                severity: 'warn',
                summary: 'Eine Periode aktivieren!',
                detail: 'Es muss zumindest eine Periode aktiviert werden.',
                life: 3000,
            });
            this.dialogLoading = false;
            this.saveWait = false;
        },
        toogleNameDiag(name) {
            this.changeDiagName = JSON.parse(JSON.stringify(name));
            this.showDiagName = true;
        },
        hideNameDiag() {
            this.changeDiagName = null;
            this.showDiagName = false;
        },
        saveNameDiag(node) {
            console.log(node);
            if (node.child1) {
                const child = JSON.parse(node.child1);
                if (child.index >= 0) {
                    console.log(child.index)
                    let obj2 = null;
                    obj2 = this.nodeDataPeriods[child.index].find((item) => item.identifier === node.identifier);
                    if (obj2) {
                        if (typeof this.changeDiagName.value === 'string' && this.changeDiagName.value.length > 0) {
                            obj2.value = this.changeDiagName.value;
                        }
                        console.log(obj2);
                    }
                }
            }
            this.hideNameDiag();
        },
        toogleLampNameDiag(name) {
            this.changeDiagLedName = JSON.parse(JSON.stringify(name));
            this.showDiagLedName = true;
        },
        hideLampNameDiag() {
            this.changeDiagLedName = null;
            this.showDiagLedName = false;
        },
        saveLampNameDiag(node) {
            console.log(node);
            if (node.child0) {
                const child = JSON.parse(node.child0);
                if (child.index >= 0) {
                    console.log(child.index)
                    let obj2 = null;
                    obj2 = this.nodeDataPhotons[child.index].find((item) => item.identifier === node.identifier);
                    if (obj2) {
                        if (typeof this.changeDiagLedName.value === 'string' && this.changeDiagLedName.value.length > 0) {
                            obj2.value = this.changeDiagLedName.value;
                        }
                        console.log(obj2);
                    }
                }
            }
            this.hideLampNameDiag();
        },
        toogleLampIdDiag(name) {
            this.changeDiagLedId = JSON.parse(JSON.stringify(name));
            this.showDiagLedId = true;
        },
        hideLampIdDiag() {
            this.changeDiagLedId = null;
            this.showDiagLedId = false;
        },
        saveLampIdDiag(node) {
            console.log(node);
            if (node.child0) {
                const child = JSON.parse(node.child0);
                if (child.index >= 0) {
                    console.log(child.index)
                    let obj2 = null;
                    obj2 = this.nodeDataPhotons[child.index].find((item) => item.identifier === node.identifier);
                    if (obj2) {
                        if (typeof this.changeDiagLedId.value === 'string' && this.changeDiagLedId.value.length > 0) {
                            obj2.value = this.changeDiagLedId.value;
                        }
                        console.log(obj2);
                    }
                }
            }
            this.hideLampIdDiag();
        },
        copyChannelsConfirmDiagOpen(index) {
            this.copyChannelsConfirmDiag = true;
            this.copyChannelIndex = index;
        },
        copyChannelsConfirmDiagClose() {
            this.copyChannelsConfirmDiag = false;
            this.copyChannelIndex = null;
        },
        copyLevelToAll(lampIndex) {
            // this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[7].daliLevel`).value
            if (Array.isArray(this.nodeDataPhotons) && this.nodeDataPhotons.length > 0 && lampIndex >= 0) {
                // console.log(lampIndex);
                this.nodeDataPhotons.forEach((entry, index) => {
                    // console.log(index);
                    for (let i=0; i<=7; i++) {
                        // const obj1 = this.nodeDataPhotons[index].find((item) => item.arrayKey === `CFG_DaliChannels[${lampIndex}].lampDaliChannels[${i}].daliLevel`);
                        const obj1 = this.getDiagDataPhotonsChannel(lampIndex, `CFG_DaliChannels[${lampIndex}].lampDaliChannels[${i}].daliLevel`);
                        // console.log(obj1);
                        let obj2 = null;
                        obj2 = this.nodeDataPhotons[index].find((item) => item.arrayKey === `CFG_DaliChannels[${index}].lampDaliChannels[${i}].daliLevel`);
                        obj2.value = obj1.value;
                        // console.log(obj2);
                    }
                });
                // for (let i=0; i<this.nodeDataPhotons.lenght; i++) {
                //     console.log(this.nodeDataPhotons);
                //     // for (let j=0; j<7; j++) {
                //     //     // this.getDiagDataPhotonsChannel(index, `CFG_DaliChannels[${index}].lampDaliChannels[7].daliLevel`).value
                        
                //     // }
                // }
            }
            this.copyChannelsConfirmDiagClose();
        },
        focusElement(event) {
            if (event !== undefined) {
                event.target.select();
            }
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            this.ioDialogData = JSON.parse(JSON.stringify(ioData));
            this.ioObj = JSON.parse(JSON.stringify(this.moduleData));
            this.entryDialog = true;
        },
        saveDialog() {
            this.submitted = true;
            if (this.ioDialogData.i) {
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        editValve(ioData) {
            console.log(ioData);
        },
        hideValveDialog() {
            this.valveDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        focusDiag(event) {
            this.periodDiagDelay(event);
        },
        periodDiagDelay(event) {
            setTimeout(() => {
                this.diagPeriod = this.periodIndex;
                this.focusElement(event);
            }, 300);
        },
        showChart(value) {
        if (value === 'value1') this.showChart1 = true;
        },
    },
});
</script>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
    // transition: opacity .5s;
}

.bounce-leave-active {
    animation: bounce-out 0.5s;
    // transition: opacity .5s;
}

.slide-fade-enter-active {
    transition: all 0.5s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for <2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.pulse-enter-active {
    animation: pulse 1s;
}

.pulse-leave-active {
    animation: pulse 0.5s;
}

.pulse-loop {
    animation: pulse 1s;
    animation-iteration-count: infinite;
}

.fade-in-enter-active {
    animation: fadeIn linear 2s;
}

.fade-in-leave-active {
    animation: fadeOut linear 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeInOut {
    0% {
        // opacity: 0;
    }
    50% {
        // opacity: 1;
        background-color: rgba(117, 18, 0, 0.5);
    }
    100% {
        // opacity: 0;
    }
}

@keyframes pulse {
    0% {
        // transform: scale(1); /* scaling to 0 */
        opacity: 0.8;
        // color: yellowgreen;
        // transform-origin: center center;
        // transform: scaleY(.9);
        text-shadow: black 2px 0 10px;
        transform: scale(0.9);
        /* increasing the size */
    }

    30% {
        transform: scale(1.1);
        /* increasing the size */
        text-shadow: gray 1px 0 10px;
    }

    100% {
        transform: scale(1);
        /* seeting back to initial size */
        opacity: 1;
    }
}

.alarm {
    animation: fadeInOut 1s infinite;
    -webkit-animation: fadeInOut 1s infinite;
}

.grid {
    margin: 0 -0.5em 0 -0.5em !important;
}

.col {
    // line-height: 1.4em !important;
    margin: 0 !important;
    padding: 0 !important;
}

.p-divider {
    margin: 0 !important;
    padding: 0 !important;
}

.box {
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}

.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
}

.text-normal {
    line-height: 0;
}

.menu-overlay {
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100px;
    opacity: 0;
    transition: 0.2s ease;
    background: none;
    text-align: right;
}

.card:hover .menu-overlay {
    opacity: 1;
}

.menu-overlay .p-button-rounded {
    width: 2rem;
    height: 2rem;
}

// .list-enter-active,
// .list-leave-active {
//   transition: all 0.5s ease;
//   transform: scale(0.7); /* increasing the size */
// }
// .list-enter-from,
// .list-leave-to {
//   opacity: 0;
//   transform: scale(1); /* increasing the size */
// }
.list-enter-active {
    animation: list 1s;
}

.list-leave-active {
    animation: list 0.5s;
}

@keyframes list {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.p-togglebutton {
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0.2rem;
    margin: 0;
}
</style>